.card_blog {
    width: 100%;
    height: auto; 
    overflow: hidden;
}

.card_blog img {
    width: 100%;
    height: 350px;
    object-fit: cover; 
}

.card_blog_body {
    padding: 20px; 
}

.card_blog_1 {
    width: 100%;
    height: auto; 
    overflow: hidden;
}

.card_blog_1 img {
    width: 100%;
    height: 300px;
    object-fit: cover; 
}


.card_blog_body_1 {
    padding: 20px; 
}

a {
    text-decoration: none;
}