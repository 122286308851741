.testimonial {
  background-color: #fff5ed;
}

.sec_6_testimonial {
  padding: 8% 0;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.testimonial_info {
  width: 700px;
}

.testimonial_sub_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonial_short_info_outer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.testimonial_short_info_inner {
  width: 450px;
  text-align: start;
  display: flex;
  gap: 20px;
}

.testimonial_short_info_inner .icon {
  font-size: 65px;
  color: tomato;
}

.testimonial_short_info_inner p {
  color: gray;
}

.testimonial_short_info_inner h4 {
  font-family: serif;
  font-weight: bold;
}

.certified_info_box {
  padding: 20px;
  width: 200px;
  background-color: #fff;
}

.certified_info_box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid tomato;
}

.swiper_card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;
}

.swiper_card h5 {
  color: tomato;
}

.swiper {
  width: 340px;
  height: 420px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-weight: bold;
  color: #fff;
}

.swiper-slide:nth-child(1n) {
  border: 1px solid red;
  background-color: white;
}

.swiper-slide:nth-child(2n) {
  border: 1px solid blue;
  background-color: white;
}

.swiper-3d .swiper-slide-shadow {
  background-color: white;
}

@media screen and (max-width: 1024px) {
  .sec_6_testimonial {
    flex-direction: column;
    gap: 10px;
  }
  .swiper {
    width: 300px;
    height: 420px;
  }

  .testimonial_sub_info {
    display: flex;
    gap: 20px;
  }

  .testimonial_info {
    width: 100%;
  }

  .testimonial_short_info_inner {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .sec_6_testimonial {
    flex-direction: column;
    gap: 10px;
  }
  .swiper {
    width: 300px;
    height: 420px;
  }

  .testimonial_sub_info {
    flex-direction: column;
    display: flex;
    gap: 10px;
  }

  .testimonial_info {
    width: 100%;
  }

  .testimonial_short_info_inner {
    width: 100%;
  }
}
