header {
  background-image: url("../../Assets/bk_1.jpg");
  width: 100%;
  padding: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-container {
  max-width: 1280px;
  padding: 94px 0;
  margin: 0 auto;
}

.hero-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}

.hero-content {
  display: flex;
  flex-direction: column;
}

.hero-title {
  margin-bottom: 16px;
  font-size: 40px;
  font-weight: bold;
}

.hero-description {
  margin-bottom: 24px;
  max-width: 640px;
  font-size: 14px;
  color: #6b7280;
}

.hero-form {
  position: relative;
  max-width: 640px;
  margin-bottom: 40px;
  padding-bottom: 32px;
}

.input_text {
  width: 80%;
  margin-top: 40px;
  padding: 18px 16px;
  font-size: 16px;
  color: black;
  border: 1px solid #cecece;
  border-radius: 8px;
  font-style: 18px;
  position: relative;
}

.search_btn {
  padding: 10px 18px;
  border-radius: 30px;
  display: inline-block;
  position: absolute;
  transition: all;
  left: 418px;
  bottom: 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.4s, border-color 0.4s;
  background-color: tomato;
  border: 1px solid transparent;
}

.search_btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 200%;
  background: linear-gradient(90deg, #ffffff33 0%, #f50b0b4d 100%);
  transition: left 0.4s ease;
  z-index: 0;
}

.search_btn:hover::before {
  left: 100%;
}

.search_btn:hover {
  color: #fff;
  border-color: tomato;
}

.search_btn span {
  position: relative;
  z-index: 1;
}

.hero-info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  max-width: 640px;
}

.info-title {
  font-size: 24px;
  font-weight: bold;
}

.info-text {
  font-size: 14px;
  color: #6b7280;
}

.hero-image img {
  width: 700px;
  position: absolute;
  top: 190px;
  right: 10px;
}

@media (min-width: 1024px) {
  .hero-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  .info-title {
    font-size: 32px;
  }
}

@media screen and (max-width: 1286px) {
  .hero-form {
    display: none;
  }
  .hero-image img {
    width: 600px;
    position: absolute;
    top: 148px;
    right: 0;
  }
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 44px;
  }
}

@media (max-width: 640px) {
  .hero-image,
  .submit-btn,
  form {
    display: none;
  }
  .hero-title {
    font-size: 20px;
  }

  .info-title,
  .info-text {
    font-size: 10px;
  }
}

@media (max-width: 1024px) {
  .hero-image,
  .submit-btn,
  form {
    display: none;
  }
  .hero-title {
    font-size: 40px;
  }

  .info-title,
  .info-text {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .hero-image img {
    display: none;
  }

  .hero-title {
    font-size: 35px;
  }

  .submit-btn {
    position: absolute;
    right: 125px;
    top: 0;
    width: auto;
  }
}
