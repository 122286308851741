.contact_page_form {
    display: flex;
}

.contact_page_form .form_body{
    width: 100%;
    border: 1px solid #d0cece;
}

.contact_page_title {
    border-bottom: 3px solid tomato;
    padding-bottom: 10px;
}

.contact_page_side_info {
    width: 100%;
}

.contact_page_side_info .icon_outer {
    background-color: white;
    border: 1px solid #8c8a8a;
    box-shadow: 5px 5px 2px #8c8a8a;
    border-radius: 50px;
    padding: 10px;
}

.contact_page_side_info .icon_outer i {
    color: tomato;
    font-size: 23px;
}

@media screen and (max-width: 479px) {
    .contact_page_form {
        flex-direction: column;
    }
}