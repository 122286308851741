.sec_2_main_page {
    padding: 8% 0;
    background-image: url("../../Assets/sec_2_bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .sec_2_intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sec_2_left_show {
    border: 1px solid #a4a4a4;
    width: 350px;
    height: 360px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-size: contain;
    background-position: center;
    background-attachment: fixed;
    background-image: url("/src/Assets/hostel_bg_left.jpg");
  }
  .sec_2_right_show {
    border: 1px solid #a4a4a4;
    width: 350px;
    height: 360px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-size: contain;
    background-position: center;
    background-attachment: fixed;
    background-image: url("../../Assets/hostel_bg.jpg");
  }
  
  .sec_2_center_intro {
    width: 380px;
  }
  
  .sec_2_center_intro h2 {
    font-family: serif;
  }
  
  .sec_2_center_intro .field_icons .icon {
    color: orange;
    padding-right: 5px;
    font-size: 25px;
  }
  
  .field_icons {
    text-align: center;
  }
  
  .field_icons p {
    margin-top: 5px;
  }
  
  .sec_2_content {
    display: flex;
    justify-content: space-between;
  }
  
  .sec_2_content_box {
    border: 1px solid #a4a4a4;
    border-radius: 5px;
    width: 300px;
    background-color: white;
  }
  
  .sec_2_content_box:hover {
    background-color: tomato;
    border: 1px solid tomato;
    cursor: pointer;
    color: white;
    transition: all 200ms ease-in-out;
  }
  
  .sec_2_content_box:hover .sec_2_icon_bg {
    background-color: #fff;
    border-radius: 10px;
  }
  
  .sec_2_icon_bg {
    border-top: 1px solid #a4a4a4;
    border-bottom: 1px solid #a4a4a4;
    border-radius: 10px;
  }
  
  .sec_2_content_box:hover p {
    color: #fff;
  }
  
  .sec_2_content_box h5 {
    font-family: serif;
  }
  
  .sec_2_content_box p {
    margin: 0;
    font-size: 13px;
    color: #a4a4a4;
  }
  
  @media screen and (max-width: 1024px) {
    .sec_2_intro {
      gap: 25px;
    }
  
    .sec_2_content {
      gap: 20px;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  
  @media screen and (max-width: 479px) {
    .sec_2_intro {
      flex-direction: column;
      gap: 25px;
    }
  
    .sec_2_content {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }
  