.navbar {
  background-image: url("../../Assets/bk_1.jpg");
  background-repeat: no-repeat;
  transition: background-color 0.3s ease;
  width: 100%;
  padding: 10px;
}

.logo {
  width: 200px;
}

.header_btn {
  background-color: transparent;
  cursor: pointer;
  border: none;
  color: tomato;
  transition: 0.2s ease-in-out;
  font-weight: bold;
}

.nav_list a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.nav_list li a {
  position: relative;
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.nav_list li a:hover {
  color: tomato;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
}

.shake-icon {
  display: inline-block;
  animation: shake 0.7s ease infinite; /* Duration and infinite loop */
  transition: transform 0.7s;
}
