.about_us {
  margin-bottom: 10%;
}

.sec_about_us_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  box-shadow: 10px 10px 10px 5px #F6F6F6, -10px -10px 10px 5px #F6F6F6;
  border-radius: 20px;
}
.color_sec {
  background-color: tomato;
}

.sec_about_us_content img {
  border-radius: 20px;
}

.sec_2_about_us_info {
  width: 500px;
}

@media (max-width: 640px) {
  .title_bck_img {
    display: none;
  }
  .content {
    font-size: 25px;
    align-items: center;
    padding-right: 0;
    margin-right: 0;
  }
  .sec_2_about_us {
    margin-top: 0;
  }

  .sec_about_us_content {
    flex-direction: column;
  }

  .sec_2_about_us_info {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .title_bck_img {
    display: none;
  }
  .content {
    font-size: 30px;
    align-items: center;
    padding-right: 0;
    margin-right: 0;
  }
  .sec_2_about_us {
    margin-top: 0;
  }

  .sub_content {
    font-size: 15px;
  }
}

@media (max-width: 1280px) {
  .title_bck_img {
    display: none;
  }
  .content {
    font-size: 30px;
    align-items: center;
    padding-right: 0;
    margin-right: 0;
  }
  .sec_2_about_us {
    margin-top: 0;
  }

  .sub_content {
    font-size: 15px;
  }
}
