.footer
{
    padding: 60px;
    background-image: url("../../Assets/bck_1.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.footer_list {
    padding: 0;
}

.footer_list li {
    list-style-type: none;
    padding: 7px 0;
}

.footer_list li a {
    text-decoration: none;
    color: #8c8a8a;
    padding: 0;
}

.footer_list li a:hover {
    color: tomato;
    transition: 0.2s ease;
}

.info
{
    color: #fff;
}

.info:hover
{
    color: blue;
    cursor: pointer;
}

.icons
{
    background-color: #fff;
    border-radius: 40px;
    padding: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.icons:hover
{
    transform: scale(1.05);
}

.icons.wt:hover
{
    background-color: rgb(77, 246, 77);
    color: #fff;
}

.icons.fb:hover
{
    background-color: rgba(2, 2, 249, 0.795);
    color: #fff;
}

.icons.yt:hover
{
    background-color: red;
    color: #fff;
}

.icons.it:hover
{
    background-color: rgb(181, 35, 139);
    color: #fff;
}

.icons.lk:hover
{
    background-color: rgba(2, 2, 249, 0.795);
    color: #fff;
}

.footer_line {
    height: 1px;
    width: 100%;
    background-color: #fff;
}

.footer_info {
    background-color: #000;
    width: 100%;
    padding: 20px;
}

.border_right {
    border-right: 1px solid #8c8a8a;
}

.highlighter {
    background-color: tomato;
    height: 3px;
    width: 20%;
}

@media screen and (max-width: 500px) {
    .border_right {
        display: none;
    }
}

