.sec_1_main_page  {
    padding: 8% 0;
  }
  
  .sec_1_box {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
  }
  
  .sec_1_box:hover img {
    transform: scale(1.1);
    transition: all 200ms linear;
  }
  
  .hostel_img {
    width: 400px;
    height: 300px;
    overflow: hidden;
  }
  
  .hostel_img img {
    width: 100%;
  }
  
  .sec_1_locations_card {
    padding: 10px;
  }
  
  .sec_1_locations_card .swiper {
    width: 100%;
    height: auto;
  }
  
  .sec_1_locations_card .swiper .swiper-slide {
    border: 1px solid #a5a2a2;
    border-radius: 5px;
  }
  
  .sec_1_box_body {
    padding: 10px;
  }
  
  .btn_booknow {
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    background-color: tomato;
    border: 1px solid transparent;
    font-weight: bold;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    overflow: hidden;
    transition: color 0.4s, border-color 0.4s;
  }
  
  .btn_booknow::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(245, 11, 11, 0.3) 100%
    );
    transition: left 0.4s ease;
    z-index: 0;
  }
  
  .btn_booknow:hover::before {
    left: 100%;
  }
  
  .btn_booknow:hover {
    color: #fff;
    border-color: tomato;
  }
  
  .btn_booknow span {
    position: relative;
    z-index: 1;
  }
  
  .field_btn {
    padding: 10px;
  }
  
  .btn_male {
    background-color: transparent;
    padding: 2px 5px;
    font-size: 12px;
    border: 1px solid #fe9900;
    width: auto;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 5px 8px #fe9900;
  }
  
  .sec_1_box_text {
    color: #949090;
  }
  
  .line {
    height: 1px;
    background-color: #a5a2a2;
  }