@font-face {
  font-family: "Lato";
  src: url("../../Assets/fonts/Lato/Lato-Regular.ttf");
}

* {
  font-family: "Lato";
}

.color {
  color: tomato;
}

.section_gap {
  margin-top: 10%;
}

ul li {
  list-style-type: none;
}

.link {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

.content {
  font-size: 50px;
  letter-spacing: 1px;
  font-weight: 400;
}

.sub_content {
  font-weight: 400;
}

.sec_about_us img {
  border-radius: 50px;
}

/* title background image position */
.title_bck_img {
  width: 400px;
  position: absolute;
  top: 95px;
  right: 563px;
}

.page_title {
  font-family: "EB Garamond", serif;
}
