.box_amenities {
    padding: 20px 0 0 0;
}

.box_amenities h6 {
    padding: 8px;
}

.box_amenities .icon {
    font-size: 22px;
    margin-right: 5px;
    margin-bottom: 2px;
}

@media (max-width: 640px) {
    .box_amenities {
        flex-wrap: wrap;
    }
}